//Генератор тела по схеме
import lodash from "lodash";

const transform = (schema, value) => {
  let result = {};
  if (schema.properties) {
    Object.keys(schema.properties).forEach((key) => {
      const item = lodash.get(schema.properties, key);
      switch (item.type) {
        case "value": {
          lodash.set(result, key, lodash.get(value, item.path, null));
          break;
        }
        case "id": {
          const _d = lodash.get(value, item.path, null);
          if (_d) {
            lodash.set(result, key, _d);
          }
          break;
        }
        case "object":
          lodash.set(result, key, transform(item, value));
          break;
        case "array": {
          let subItems = [];
          lodash.get(value, item.path, []).forEach((subItem) => {
            subItems.push(transform(item.items, subItem));
          });
          lodash.set(result, key, subItems);
          break;
        }
      }
    });
  }
  return result;
};

export default transform;
